.your-domains-window {
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.0025em;
    margin-bottom: 36px;

    color: rgba(0, 0, 0, 0.87);
  }

  &__grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
    gap: 24px;
  }
  &__domain-item {
    width: 220px;
    height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    padding: 14px 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  &__status {
    display: flex;
    justify-content: right;

    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 4px;

    width: 100%;
  }
  &__domain-title {
    font-size: 14px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.6);
  }
  &__domain-name {
    font-size: 18px;
    line-height: 21px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__domain-add {
    width: 130px;
    height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    cursor: pointer;
    text-decoration: none;

    transition: transform 0.3s;

    &:hover {
      transform: scale(1.02);
    }
  }
  &__add-plus {
    font-weight: 300;
    font-size: 64px;
    line-height: 64px;
    color: rgba(0, 0, 0, 0.87);
  }
  &__add-text {
    font-size: 14px;
    line-height: 16px;

    color: #3367d6;
  }
}
