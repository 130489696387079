.status-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  padding: 3px 8px;
  border-radius: 40px;

  &--success {
    background-color: #e4f3e1;
    color: #4db137;
  }

  &--warning {
    background-color: #fff4e7;
    color: #f8a52a;
  }

  &--error {
    background-color: #fde0d9;
    color: #f02f04;
  }
  &--trial {
    background: rgba(51, 103, 214, 0.15);
    color: #3367d6;
  }
}
