.dashboard-window {
  &__modal-text-2 {
    margin-top: 8px;
  }
  &__header {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__title {
    margin-bottom: 0;
  }
  &__header-buttons {
    display: flex;
    gap: 22px;
  }
  &__header-info {
    display: flex;
    gap: 52px;
  }
  &__info-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__info-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #666666;
  }
  &__info-text {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    color: rgba(0, 0, 0, 0.87);
  }

  &__content {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(288px, 288px));
    gap: 24px;
  }

  &__card {
    padding: 16px;

    background: #ffffff;

    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-add-seats {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 0 0 0;

      color: #3367d6;
      border: none;
      outline: none;
      background-color: transparent;

      transition: transform 0.3s;
      &:hover {
        transform: scale(0.97);
      }
    }
  }
  &__card-tags {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }
  &__domain-name {
    font-size: 14px;
    line-height: 16px;

    margin-bottom: 24px;
    color: #666666;
  }
  &__domain-product {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    margin-bottom: 8px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__domain-product-type {
    font-size: 14px;
    line-height: 16px;

    margin-bottom: 24px;

    color: #666666;
  }
  &__domain-licenses-box {
    display: flex;
    justify-content: space-between;

    font-size: 12px;
    line-height: 14px;

    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.6);
  }
  &__domain-pregress-box {
    line-height: 3px;
  }
  &__domain-pregress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  progress {
    height: 3px;
    width: 100%;

    background: #3367d6;
    border-radius: 2px;
  }
  progress::-webkit-progress-bar {
    background-color: lightgrey;
    border-radius: 2px;
  }
  progress::-webkit-progress-value {
    background-color: #3367d6;
    border-radius: 2px;
  }

  &__divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 1px;
    margin: 16px 0;
  }

  &__saved-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;

    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.87);
  }
  &__saved-subtitle {
    font-size: 12px;
    line-height: 14px;

    color: rgba(0, 0, 0, 0.6);
  }
  &__cost-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.text-bolt-1 {
  font-weight: 600;
}
.modal-seats {
  max-width: 400px;

  &__plan {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.87);
  }
  &__header-body {
    display: flex;
    gap: 40px;
  }
  &__header-numbers {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__header-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0025em;

    color: #666666;
  }
  &__header-text--bolt {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.75);
  }
  &__value-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.87);

    margin-bottom: 16px;
  }
  &__info-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__info-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    color: #666666;
  }
}
