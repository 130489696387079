.support-window {
  display: flex;
  flex-direction: column;
  max-width: 340px;
  min-width: 330px;

  @media screen and (max-width: 480px){
    padding: 18px;
  }

  &__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.0025em;

    margin-bottom: 34px;

    color: rgba(0, 0, 0, 0.87);
  }

  &__sub-title {
    margin-bottom: 38px;
  }

  &__input {
    margin-bottom: 8px;
  }

  &__send-button {
    font-size: 14px;
    line-height: 16px;
    outline: none;
    border: none;

    color: #FFFFFF;
    background: #3367D6;
    border-radius: 4px;
    padding: 12px;
    max-width: 70px;

    transition: color 0.3s, background-color 0.3s;

    &:hover {
      color: #3367D6;
      background: #a6c3ff;
    }
  }
}
