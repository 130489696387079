.input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
  background-color: white;

  padding: 12px 14px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.6);
  }

  &-sub-info {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.6);

    &--error {
      color: #ff0033;
    }
    &--absolute {
      position: absolute;
      top: 54px;
      left: 12px;
    }
  }

  &--error {
    border-color: #ff0033;
  }

  &__group {
    display: flex;
    gap: 24px;
    width: 100%;
  }
}

// Reset basic select styles
select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;

  &::-ms-expand {
    display: none;
  }

  outline: none;
}
// TO USE: use this class as a wrapper of select tag
.input--select {
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #666666;
    clip-path: polygon(50% 45%, 0 0, 100% 0);
    position: absolute;
    top: 22px;
    right: 16px;
  }
}
.input--placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
