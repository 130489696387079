.modalBase {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 98;
  backdrop-filter: blur(2px);
}

.modalWindow {
  border-radius: 4px;
  padding: 34px 24px 8px;
  background-color: white;
  z-index: 99;
  max-width: 500px;

  -webkit-box-shadow: 16px 16px 66px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 16px 16px 66px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 16px 16px 66px 0px rgba(0, 0, 0, 0.75);
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: 0.0025em;
  margin-bottom: 24px;

  color: rgba(0, 0, 0, 0.87);
}
.content {
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.0025em;
  margin-bottom: 40px;

  color: rgba(0, 0, 0, 0.75);
}
.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 8px;
}
