.button {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 16px;

  font-family: inherit;
  border: none;
  outline: none;
  max-width: max-content;

  transition: transform 0.2s;

  &--primary {
    color: #ffffff;
    background: #3367d6;
    border-radius: 4px;

    &:hover {
      background: #2d59b6;
    }
    &:active {
      background: #2d59a2;
    }
  }

  &--secondary {
    color: #3367d6;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &:hover {
      background: #fafafa;
    }
    &:active {
      background: #ebebeb;
    }
  }

  &--fullwidth {
    width: 100%;
    max-width: 100%;
  }

  &:active {
    transform: scale(0.97);
  }
}

.button:disabled {
  background-color: #404040;
  color: #787878;
  cursor: not-allowed;
}
