.w-400 {
  width: 400px;
}
.w-100p {
  width: 100%;
}
.w-50p {
  width: 50%;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mt-24 {
  margin-top: 24px;
}
.disp-none {
  display: none;
}
.text--bolt {
  font-weight: bold;
}
.text--color-blue {
  color: #3367d6;
}
.pos-rel {
  position: relative;
}
.loader--center {
  display: flex;
  justify-content: center;
  align-items: center;
}
