* {
  box-sizing: border-box;
}
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
ul,
li,
ol,
a,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
