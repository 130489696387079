.page {
  display: flex;
  flex: 1;

  background: #eeeff4;

  &__content {
    padding: 50px 24px;
    width: 100%;
    max-height: 90vh;
    overflow: scroll;
  }
  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.87);

    margin: 0;
    margin-bottom: 36px;
  }
}
