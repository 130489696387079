.pageNotFound {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 24px;

  height: 92vh;
}
.textGroup {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
}
.text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #666666;
}
.code {
  font-weight: 400;
  font-size: 56px;
  line-height: 66px;

  color: #666666;
}
.img {
}
.textColorBlack {
  color: #000000;
}
