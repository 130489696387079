.header {
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);

  @media screen and (max-width: 760px) {
    & {
      display: none;
    }
  }

  &__left-group {
    display: flex;
  }

  &__logo {
    width: 214px;
    padding: 0 44px 10px 24px;
  }

  &__nav-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);

    display: inline-block;
    padding: 24px 0;
    transition: color 0.3s;

    &:not(:last-child) {
      margin-right: 45px;
    }

    &:hover {
      color: #3367d6;
    }
  }

  &__right-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__support {
    margin: 0 20px;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: scale(0.97);
    }
  }
  &__profile {
    padding: 10px 14px;
    margin-right: 24px;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: scale(0.97);
    }
  }

  &__profile-img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
  &__pop-up {
    position: absolute;
    top: 4px;
    right: 22px;
    padding: 18px 44px 18px 14px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
  }
  &__popup-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
