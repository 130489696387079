.error-message {
  position: fixed;
  bottom: 32px;
  left: 50%;
  min-width: 350px;
  transform: translateX(-30%);
  padding: 11px 25px 11px 14px;
  background: rgba(0, 0, 0, 0.87);

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.004em;

    color: rgba(255, 255, 255, 0.87);
  }
}
