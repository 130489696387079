.transfer-token-window {
  margin: 64px auto 16px;
  display: flex;
  flex-direction: column;
  gap: 96px;
  max-width: 960px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  &__logos {
    display: flex;
    gap: 66px;
  }
  &__title {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.0025em;

    color: rgba(0, 0, 0, 0.87);
  }
  &__logos {
  }
  &__content {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 36px 24px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
