.page {
  min-height: 92vh;
  display: flex;
  flex: 1;

  background: #eeeff4;
}
.content {
  padding: 50px 24px;
  width: 100%;
  max-height: 90vh;
  overflow: scroll;
}
.thankYou {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;

  height: 100%;
  font-size: 48px;
  font-weight: 200;
  text-align: center;
}
.bigText {
  font-weight: 400;
  font-size: 56px;
  line-height: 66px;

  color: #000000;
}
.subText {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #666666;
}
