@import './styles/Buttons';
@import './styles/Inputs';
@import './styles/StatusTags';
@import './styles/Basic';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main {
  display: flex;
  flex: 1;
}
.mobile-not {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: none;
  justify-content: center;
  align-items: center;

  z-index: 99;
  background-color: white;
  font-size: 22px;
  font-weight: 400;

  @media screen and (max-width: 760px) {
    & {
      display: flex;
    }
  }
}
