.sidebar {
  width: 220px;
  min-height: 100%;
  padding: 32px 16px 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #FFFFFF;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 760px) {
    & {
      display: none;
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
  }

  &__nav-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;

    & > svg {
      fill: #000000;
      fill-opacity: 0.6;
    }

    &--is-active {
      font-weight: 600;
      & > svg {
        fill: #3367D6;
        fill-opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:hover {
      color: #3367D6;
    }
  }
  &__nav-link-icon {
    margin-right: 8px;
  }
}
