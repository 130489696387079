.sign-up-window {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 0;
}

.formTransitionCL-1 {
  &-enter {
    position: absolute;
    opacity: 0;
    transform: translateX(-100%);
  }
  &-enter-active {
    position: static;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  &-exit {
    position: static;
    opacity: 1;
    transform: translateX(0);
  }
  &-exit-active {
    position: absolute;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 500ms;
  }
}

.formTransitionCL-2 {
  &-enter {
    position: absolute;
    opacity: 0;
    transform: translateX(100%);
  }
  &-enter-active {
    position: static;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  &-exit {
    position: static;
    opacity: 1;
    transform: translateX(0);
  }
  &-exit-active {
    position: absolute;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms, transform 500ms;
  }
}
.sign-up-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(2px);
}
