.sign-up-form-two {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  padding: 64px 42px;
  width: max-content;

  &__google-img {
    margin-bottom: 32px;
  }

  &__form-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;

    letter-spacing: 0.0025em;
    margin-bottom: 20px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__form-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    letter-spacing: 0.0025em;
    margin-bottom: 34px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__input-group {
    display: flex;
    gap: 8px;
    width: 100%;
  }
  &__group {
    display: flex;
    flex-direction: column;
  }

  &__buttons-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 58px;
  }
  &__checkbox-title {
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.0025em;

    margin-left: 8px;
    color: rgba(0, 0, 0, 0.87);
  }
  &__terms {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;

    letter-spacing: 0.0015em;

    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 52px;

    &--link {
      color: #3367d6;
    }
  }
}
