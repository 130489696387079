.billing-window {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }
  &__title {
  }
  &__payment {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  &__sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0025em;

    display: inline-block;

    color: rgba(0, 0, 0, 0.87);
  }
  &__header-group-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    margin-right: 8px;

    color: rgba(0, 0, 0, 0.87);
  }

  &__content {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 330px;
  }
  &__form-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0025em;

    margin-bottom: 20px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__input-group {
    display: flex;
    gap: 24px;
  }
  &__input {
    margin-bottom: 24px;

    &--no-margin {
      margin: 0;
    }
    &--30 {
      flex-basis: 33%;
      min-width: 10px;
    }
    &--70 {
      flex-basis: 66%;
    }
  }
  &__input-info {
    top: 52px;
    left: 12px;
    position: absolute;
  }

  &__buttons-group {
    display: flex;
    gap: 16px;
    justify-content: right;
  }

  &__plan-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 340px;
    max-width: 360px;

    padding: 32px 24px;

    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  &__plan-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0025em;

    margin-bottom: 24px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__plan-text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    margin-bottom: 16px;

    color: rgba(0, 0, 0, 0.87);

    &--bolt {
      font-weight: 600;
    }
    &--regular {
      font-weight: 400;
    }
  }
  &__button-main {
    margin-bottom: 24px;
  }
  &__button-secondary {
    margin-bottom: 8px;
  }
  &__plan-terms-price {
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.0025em;
    margin-bottom: 8px;

    color: rgba(0, 0, 0, 0.6);
  }
  &__plan-terms-link {
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.0025em;

    color: #3367d6;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__card {
    padding: 24px;
    min-width: 380px;
    height: 150px;
    background: #ffffff;

    border: 1px solid #e0e0e0;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--active {
      border: 1px solid #3367d6;
    }
    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
    &-bank {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.0025em;

      color: rgba(0, 0, 0, 0.6);
    }
    &-number {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0025em;

      color: rgba(0, 0, 0, 0.87);
    }
    &-current-tag {
      font-size: 14px;
      line-height: 16px;

      color: #3367d6;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__add-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #3367d6;
  }
}
#solid-payment-form-iframe {
  width: 100%;
}

div[class='form-container'] {
  display: flex;
  flex-direction: column;
}
.form_body > div.form-container {
  padding: 0 !important;
}
.billing-loader-box {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}
