.transfer-step {
  display: flex;
  flex-direction: column;
  justify-content: left;

  margin-bottom: 56px;

  &__icon {
    margin-bottom: 28px;
    width: 30px;
  }
  &__text {
    margin-bottom: 32px;
    white-space: pre-line;
  }
  &__image {
    object-fit: cover;
    max-height: 550px;
  }
}
.transfer__pw-code {
  background: #e7edfa;
  border-radius: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.0015em;
  padding: 0 10px;

  color: #3367d6;
  outline: none;
  border: none;

  &:active {
    background: #bcbdd4;
    transform: scale(0.97);
  }
  & > img {
    padding: 6px 0 0 4px;
  }
}
.transfer__pw-link {
  color: #3367d6;
  text-decoration: none;
}
