.sing-in-window {
  display: flex;
  flex-direction: column;
  min-width: 450px;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #ffffff;

  padding: 40px;

  &__logo {
    margin-bottom: 30px;
    width: 120px;
    align-self: center;
  }
  &__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.0025em;

    align-self: center;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.87);
  }
  &__divider {
    position: relative;
    align-self: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0025em;

    margin-bottom: 14px;
    color: rgba(0, 0, 0, 0.6);

    &:before {
      content: '';
      position: absolute;
      right: 120%;
      top: 50%;

      display: block;
      height: 1px;
      width: 1500%;
      background-color: rgba(0, 0, 0, 0.12);
    }
    &:after {
      content: '';
      position: absolute;
      left: 120%;
      top: 50%;

      display: block;
      height: 1px;
      width: 1500%;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  &__input {
    padding: 14px 15px;

    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 18px;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0025em;

      color: #666666;
    }
  }
  &__checkbox {
    margin-bottom: 50px;
  }
  &__links {
    display: flex;
    justify-content: space-between;
  }
  &__create-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    text-decoration: none;
    color: #3367d6;
  }
  &__login-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    height: max-content;
    padding: 10px 14px;

    text-decoration: none;
    background: #3367d6;
    border-radius: 4px;
    color: #ffffff;
    transition: color 0.3s, background 0.3s;

    &:hover {
      background: #c7d8ff;
      color: #3367d6;
    }
  }

  &__modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.googleGSI-button {
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}
