.information-window {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
  }
  &__header-buttons {
    display: flex;
    gap: 12px;
  }
  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.0025em;
    color: rgba(0, 0, 0, 0.87);
  }
  &__content {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__form {
    // flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 330px;
  }
  &__form-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0025em;

    margin-bottom: 20px;

    color: rgba(0, 0, 0, 0.87);
  }
  &__form-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 330px;
    margin-bottom: 52px;

    color: #000000;
  }
  &__input {
    margin-bottom: 24px;

    &--no-margin {
      margin: 0;
    }
    &--30 {
      flex-basis: 33%;
      min-width: 10px;
    }
    &--70 {
      flex-basis: 66%;
    }
  }
  &__save-btn {
    display: flex;
    justify-content: end;
  }
}
